export const GALLERY_DATA = {"galleryData": [
    {
        "value": "数量词",
        "hanzis": ["一","二","三","四","五","六","七","八","九","十","两","半","几","多","少","个","只","片","双","串","点","条","群","步"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"],
        "freebooks":[""]
    },
    {
        "value": "身体",
        "hanzis": ["目","耳","口","牙","手","足","心","头","皮","发","尾","巴","步"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"],
        "freebooks":[""]
    },
    {
        "value": "自然",
        "hanzis": ["天","地","气","云","风","雨","雪","水","江","海","日","月","星","影","山","石","土","火","尘","洞","声"],
        "freevideos": ["http://open.iqiyi.com/developer/player_js/coopPlayerIndex.html?vid=73b8a484b4bd2dde12fba935a2cbe7fb&tvId=10130954300&accessToken=2.f22860a2479ad60d8da7697274de9346&appKey=3955c3425820435e86d0f4cdfe56f5e7&appId=1368&height=100%&width=100%"],
        "freebooks":["https://pan.baidu.com/s/12wLZmH_9KppUa05dzqrjrQ"]
    },
    {
        "value": "植物",
        "hanzis": ["木","林","森","树","叶","本","果","杏","苹","芽","草","花","莲","桃","田","禾","竹","豆","瓜"],
        "freevideos": ["//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"],
        "freebooks":["https://pan.baidu.com/s/1WI3MySvHcjaS8ik2p_i9Xw"]
    },
    {
        "value": "动物",
        "hanzis": ["鼠","鸟","虫","鱼","兔","马","牛","羊","猫","狗","鸡","鸭","鸦","蛙","惊蛰"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"],
        "freebooks":["https://pan.baidu.com/s/1yWhTMYKSwb1rWY9dw7AOlw"]
    },
    {
        "value": "人物",
        "hanzis": ["人","女","男","爸","妈","姐","妹","哥","弟","公","爷","叔","师","朋","友","我","你","他","她","它","们","自","己","子","孩","儿","娃","众"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"],
        "freebooks":[""]
    },
    {
        "value": "东西",
        "hanzis": ["伞","衣","包","书","纸","笔","尺","刀","工","力","贝","桌","棋","旗","医","奶","面"],
        "freevideos": ["//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"],
        "freebooks":["https://pan.baidu.com/s/1OWXEJ59H-qHAmvShFNXtYg"]
    },
    {
        "value": "建筑交通",
        "hanzis": ["车","船","桥","台","家","院","厂","道"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78717162&cid=134688271&page=1"],
        "freebooks":["https://pan.baidu.com/s/18weDbd0nieBvbji1L23Utg"]
    },
    {
        "value": "颜色",
        "hanzis": ["红","绿","蓝","黄","金","黑","乌","青","白","彩","色"],
        "freevideos": [""],
        "freebooks":[""]
    },
    {
        "value": "方向方位",
        "hanzis": ["东","西","南","北","上","中","下","左","右","前","后","来","去","回","到","出","进","在","向","这","那","里","旁","边","过","处","远","近"],
        "freevideos": ["http://open.iqiyi.com/developer/player_js/coopPlayerIndex.html?vid=80badd52e43f9da6a892495fa2f7dffb&tvId=9533290700&accessToken=2.f22860a2479ad60d8da7697274de9346&appKey=3955c3425820435e86d0f4cdfe56f5e7&appId=1368&height=100%&width=100%"],
        "freebooks":[""]
    },
    {
        "value": "节气",
        "hanzis": ["立春","雨水","惊蛰","春分","清明","谷雨","立夏","小满","芒种","夏至","小暑","大暑","立秋","处暑","白露","秋分","寒露","霜降","立冬","小雪","大雪","冬至","小寒","大寒","节气"],
        "freevideos": ["http://open.iqiyi.com/developer/player_js/coopPlayerIndex.html?vid=3f33ecced27250a43bab4886716034c0&tvId=12143230200&accessToken=2.f22860a2479ad60d8da7697274de9346&appKey=3955c3425820435e86d0f4cdfe56f5e7&appId=1368&height=100%&width=100%","//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"],
        "freebooks":["https://pan.baidu.com/s/1XnELkyvbldxRvsXfUEZwGw"]
    },
    {
        "value": "冬",
        "hanzis": ["冬","立冬","小雪","大雪","冬至","小寒","大寒"],
        "freevideos": ["//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"],
        "freebooks":["https://pan.baidu.com/s/1XnELkyvbldxRvsXfUEZwGw"]
    },
    {
        "value": "大",
        "hanzis": ["大","大暑","大雪","大寒"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"],
        "freebooks":[""]
    },
    {
        "value": "寒",
        "hanzis": ["寒露","小寒","大寒"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"],
        "freebooks":[""]
    },
    {
        "value": "小",
        "hanzis": ["小","小暑","小雪","小寒"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"],
        "freebooks":[""]
    },
    {
        "value": "春",
        "hanzis": ["春","立春","雨水","惊蛰","春分","清明","谷雨"],
        "freevideos": [""],
        "freebooks":[""]
    },
    {
        "value": "夏",
        "hanzis": ["夏","立夏","小满","芒种","夏至","小暑","大暑"],
        "freevideos": [""],
        "freebooks":[""]
    },
    {
        "value": "秋",
        "hanzis": ["秋","立秋","处暑","白露","秋分","寒露","霜降"],
        "freevideos": [""],
        "freebooks":[""]
    },
    {
        "value": "季节",
        "hanzis": ["春","夏","秋","冬","立春","春分","立夏","夏至","立秋","秋分","立冬","冬至"],
        "freevideos": ["player.bilibili.com/player.html?aid=79827333&cid=136620505&page=1"],
        "freebooks":["https://pan.baidu.com/s/1w-ssMvRFZoLne8THteGlgg"]
    },
    {
        "value": "时间",
        "hanzis": ["早","午","晚","昨","今","明","年","月","日","时","候","久"],
        "freevideos": ["http://open.iqiyi.com/developer/player_js/coopPlayerIndex.html?vid=1142930dd7a63cc0b72a36efd6255ef0&tvId=10665472200&accessToken=2.f22860a2479ad60d8da7697274de9346&appKey=3955c3425820435e86d0f4cdfe56f5e7&appId=1368&height=100%&width=100%"],
        "freebooks":["https://pan.baidu.com/s/1fgc8Lv1uGaDk5rBI14Tl4g"]
    },
    {
        "value": "学习",
        "hanzis": ["国","校","学","文","字","写","词","语","句","诗","音","歌","画","数","方","法","办","会","作","业"],
        "freevideos": ["//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"],
        "freebooks":["https://pan.baidu.com/s/1y_ZDH74TTVNZGO8tQfFZ5A"]
    },
    {
        "value": "动词",
        "hanzis": ["立","站","坐","走","爬","飞","睡","觉","看","见","听","说","问","闪","用","住","玩","笑","穿","打","挂","找","给","放","许","变","更","参","加","服","得","从","比","升","起","开","关"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"],
        "freebooks":[""]
    },
    {
        "value": "形容词",
        "hanzis": ["大","小","尖","长","短","空","弯","高","亮","明","快","乐","活","美","丽","好","老","真","正","反","全","当","对","同"],
        "freevideos": ["//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"],
        "freebooks":[""]
    },
    {
        "value": "虚词",
        "hanzis": ["和","又","也","的","了","着","是","不","无","没","有","什","么","谁","呀","吧","吗","才","还","可","以","很","最","就","要","把","成","为"],
        "freevideos": [""],
        "freebooks":[""]
    },
    {
        "value": "宝盖头的字",
        "hanzis": ["宀","宵","宅","家","安","字","它"],
        "freevideos": [""],
        "freebooks":[""]
    },
]
};