import React from 'react';
import { Layout, Icon, Button, } from 'antd';
import 'antd/dist/antd.css';
import './Style.css';
import ButtonGroup from 'antd/lib/button/button-group';

export default () => {
  const headerTitle="一起画汉字";
  const hanziTitle="有趣的汉字";
  const gushiTitle="古诗";
  const jieqiTitle="节气";

  return (
        <Layout collapsible theme="light" style={{height:42}} >
          <Button className="header" type="danger" target="_blank" href="https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=MzU0NTQ2NTE4Mg==&scene=124#wechat_redirect" size="large" block >
            <Icon type="smile" />
            {headerTitle}
            <ButtonGroup>
              <Button type="link" href="" size="large" >
              </Button>
              <Button type="primary" href="" size="small" >
              {hanziTitle}
              </Button>
              <Button type="link" href="https://mp.weixin.qq.com/mp/homepage?__biz=MzU0NTQ2NTE4Mg==&hid=5&sn=03ba42b193f371952f5edf3db3686c08" size="small">
              {gushiTitle}
              </Button>
              <Button type="link" href="https://mp.weixin.qq.com/mp/homepage?__biz=MzU0NTQ2NTE4Mg==&hid=3&sn=1dfb4bc6d39b8ddbd7a04f83f94f09e3" size="small">
              {jieqiTitle}
              </Button>
            </ButtonGroup>
          </Button>
        </Layout>
  );
};

// old code: 
/*
    <div className="navbar">
    <ul mode="horizontal" className="barstyle">
      <li className="logostyle" key="logo">
        <Icon className="Iconstyle" type="api" />
        <a rel="noopener noreferrer" target="_blank">小淇画汉字</a>
      </li>
      <li key="学古诗">



      */