import React from 'react';
import { Layout, Button, Divider} from 'antd';
//import './Style.css';
import 'antd/dist/antd.css';

export default () => {
  const footerText0="Copyright © 2019 - 2020";
  const footerText1="一起画汉字";
  const footerText2="HanziKid.com All rights received.";
  return (
    <Layout theme="light">
    <Divider />
    <Button className="footer" type="normal" href="" size="small">
        {footerText0}
        <Button type="link" href="mailto:xiaoqihuahanzi@outlook.com" size="small" >
        {footerText1}
        </Button>
        <Divider type="vertical" />
        {footerText2}
    </Button>
    </Layout>
  );
};