import React from 'react';
import { getHanziData, getHanziByOffset, getHanziGallery, getGalleryOfRadical, getGalleryOfCat } from './data';
import 'antd/dist/antd.css';
import './Style.css';
import { Icon, Button, Divider, Tabs, BackTop, Spin } from 'antd';
import Search from 'antd/lib/input/Search';

// tabs
const {TabPane} = Tabs

// 汉字画
class Hanzihua extends React.Component {
  render() {
    //const clickedCls = this.props.clicked ? "clicked" : ""; //className={`image ${clickedCls}`} 
    return (
      <img
        src={this.props.img} 
        className="hanzihua"
        alt="" 
      /> //alt="努力创作中..." 
    )
  }
}

//汉字画Modal

// 将所有同类字的缩小图显示在当前汉字画下方
class Thumbnail extends React.Component {
  render() {
    return (
      <img className="thumbnail" src={this.props.img} onClick={this.props.onClick} alt=""/>
    )
  }
}

// 按笔画/类别分的同类字的缩小图
class ThumbByCat extends React.Component {
  render() {
    return (
      <img className="thumbbycat" src={this.props.img} onClick={this.props.onClick} alt=""/>
    )
  }
}

// 免费汉字视频
class HanziVideo extends React.Component {
  render() {
    return (
      <iframe 
        title={"小淇画汉字视频"}
        className="video"
        src={this.props.freevideo}
        scrolling="no" 
        border="0" 
        frameBorder="no" 
        framespacing="0" 
        allowFullScreen="true" /> 
    )
  }
}

// 付费视频按钮
class PayVideoButton extends React.Component {
  render() {
    const buttonTitle = "    汉字课"
    return (
      <Button id="PayVideoButton" className="videobutton" 
        target="_blank" href={this.props.payvideo} 
        type="danger"
        disabled={"NA"!==this.props.payvideo ? false : true } >
        <Icon className="Iconstyle" type="youtube" />
        {buttonTitle}
      </Button>
    )
  }
}

// 下载电子书
class DownloadButton extends React.Component {

  render() {
    const buttonTitle = "    电子书"
    return (
      <Button id="DownloadButton" className="bookbutton" 
        target="_blank" href={this.props.gallerybook} 
        type="danger"
        disabled={this.props.gallerybook ? false : true } >
        <Icon className="Iconstyle" type="read" />
        {buttonTitle}
      </Button>
    )
  }
}

// 汉字浏览按钮
class BrowseHanziButton extends React.Component {

  constructor(props) {
    super(props);
    const { direction } = this.props;
    let text, icon;
    if (direction === 1) {
      //text = " 下一个";
      icon = "right"
    } else {
      //text = " 上一个";
      icon = "left"
    }
    this.state = {
      text,
      icon
    }
  }

  render() {
    return (
      <Button id="BrowseHanziButton" className={`browsebuttons ${this.state.icon}`} onClick={this.props.onClick} shape="circle" type="link" size="large">
        <Icon className="Iconstyle" type={this.state.icon} />
        {this.state.text}
      </Button>
    )
  }
}

// 搜索框
class SearchBar extends React.Component {
  render() {
    return (
      <Search 
      id="searchInput"
      className="searchInput" 
      placeholder={this.props.hanzi}
      onSearch={this.props.onSubmit}
      onPressEnter={this.props.onSubmit}
      enterButton 
      />
    )
  }
}

/* old code for SearchBar
      <div className="search" >
        {"输入一个字："} <br /> 
        <input id="searchInput" ref="searchInput" type="text" hint="比如：只" />
        <button classname="searchsubmit" onClick={this.props.onSubmit} type="primary">
        找一找  
          <Icon className="Iconstyle" type="search" />
        </button>   
      </div> 
*/

// ---------- 部首/类别按钮 ---------- 
class RadicalButton extends React.Component {
  render () {
    return (
      <Button className="radicalbutton" onClick={this.props.onClick} type="default">
        {this.props.option}
      </Button>
    )
  }
}

// 悬浮付费视频按钮
class FloatVideoButton extends React.Component {
  render() {
    return (
      <Button id="FloatVideoButton" className="floatvideobutton" 
        target={"NA"!==this.props.payvideo ? "_blank" : "" }
        href={this.props.payvideo} 
        shape="circle"
        size="large"
        type="danger"
        disabled={"NA"!==this.props.payvideo ? false : true } >
        <Icon className="floatvideobuttonicon" type="play-square" />
      </Button>
    )
  }
}

// 汉字搜索结果
class SearchHanzi extends React.Component {

    constructor(props) {
      super(props);
      const { type, value, secondvalue, tab } = this.getPathFromLink();

      const {
        hanzi,
        galleries,
        galleryOfRadical,
        hanziOfRadical,
        hanziImageOfRadical,
        hanziPayVideoOfRadical,
        galleryOfCat,
        hanziOfCat,
        hanziImageOfCat,
        hanziPayVideoOfCat
      } = this.updateStateForPage(type, value, secondvalue);

      this.state = {
        // ---------- single hanzi-related ---------- 
        hanzi: hanzi || "树", // 默认值："只"
        hanziImage: this.getImage(hanzi) || this.getImage("树"),
        hanziPayVideo: this.getPayVideo(hanzi) || this.getPayVideo("树"),
        // gallery-related
        galleries: galleries || this.getGallery("树"),
        gallerybook: this.getGalleryBook(hanzi) || this.getGalleryBook("树"),
        freevideo: "NA",
        currentTab: tab || "3",
        //errors: '',
        // ---------- radicals related ---------- 
        radicals: ["刂","亻","人","彳","女","子","八","阝","⺈","勹","亠","冖","宀","讠","扌","艹","口","囗","门","氵","彡","纟","忄","辶","灬","夂","攵","立","走","土","日","月","雨","目","木","禾","⺮","犭","鸟","虫","穴","𤣩"],
        galleryOfRadical: galleryOfRadical || ["木","林","森","树","本","果"], // ["宀","宵","家","宅","安","字","它"],
        radical: type === 'radical' ? value : "木",
        hanziOfRadical: hanziOfRadical || "树",
        hanziImageOfRadical: hanziImageOfRadical || this.getImage("树"),
        hanziPayVideoOfRadical: hanziPayVideoOfRadical || this.getPayVideo("树"),
        // ---------- categories related ----------
        categories: ["节气","季节","自然","植物","动物","身体","人物","动作","物品","交通","建筑","学习","颜色","方位","时间","数量词","形容词","虚词"],
        galleryOfCat: galleryOfCat || ["立春","雨水","惊蛰","春分","清明","谷雨"],
        category: type === 'category' ? value : "清明",
        hanziOfCat: hanziOfCat || "清明",
        hanziImageOfCat: hanziImageOfCat || this.getImage("清明"),
        hanziPayVideoOfCat: hanziPayVideoOfCat || this.getPayVideo("清明"),
        // ---------- for loading spin ----------
        isLoading: false,
      };
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handleBrowseHanzi = this.handleBrowseHanzi.bind(this);
      this.handleClickThumbnail = this.handleClickThumbnail.bind(this);
      this.handleClickRadical = this.handleClickRadical.bind(this);
      this.handleClickThumbByCat = this.handleClickThumbByCat.bind(this);
    }

    getPathFromLink() {
      const link = decodeURIComponent(window.location.pathname);
      const paths = link.split('/');
      const type = paths.length>1 ? paths[1] : "" ;
      const value = paths.length>2 ? paths[2] : "" ;
      const secondvalue = paths.length===4 ? paths[3] : "" ;

      let tab = '3';
      switch(type) {
        case 'search' :
          tab = '1';
          break;
        case 'category' :
          tab = '3';
          break;
        case 'radical' :
          tab = '2';
          break;
        default:
          tab = '3';
          break;
      }

      return { type, value, tab, secondvalue };
    }

    updateStateForPage(type, value, secondvalue) {
      let hanzi = null, 
        galleries = null,
        galleryOfRadical = null,
        hanziOfRadical = null,
        hanziImageOfRadical = null,
        hanziPayVideoOfRadical = null,
        galleryOfCat = null,
        hanziOfCat = null,
        hanziImageOfCat = null,
        hanziPayVideoOfCat = null;

      switch(type) {
        case 'search': 
          hanzi = value || "";
          galleries = this.getGallery(value);
          break;
        case 'category':
          const catState = this.getCatGalleryState(value);
          const catHanziState = this.getCatHanziState(secondvalue);
          galleryOfCat = catState.galleries;
          hanziOfCat = catHanziState.hanzi || catState.hanzi;
          hanziImageOfCat = catHanziState.img || catState.img;
          hanziPayVideoOfCat = catHanziState.video || catState.video;
          break;
        case 'radical':
          const radState = this.getRadicalGalleryState(value);
          const radHanziState = this.getCatHanziState(secondvalue);
          galleryOfRadical = radState.galleries;
          hanziOfRadical = radHanziState.hanzi || radState.hanzi;
          hanziImageOfRadical = radHanziState.img || radState.img;
          hanziPayVideoOfRadical = radHanziState.video || radState.video;
          break;
        default:
          break;
      }

      return {
        hanzi,
        galleries,
        galleryOfRadical,
        hanziOfRadical,
        hanziImageOfRadical,
        hanziPayVideoOfRadical,
        galleryOfCat,
        hanziOfCat,
        hanziImageOfCat,
        hanziPayVideoOfCat
      };
    }

    getImage(hanzi) {
      const data = getHanziData(hanzi,0)
      return data.imgs;
    }

    getFreeVideo(hanzi) {
      const data = getHanziGallery(hanzi)
      return data.galleryvideo;
    }

    getPayVideo(hanzi) {
      const data = getHanziData(hanzi,0)
      return data.payvideo;
    }

    getGallery(hanzi) {
      const data = getHanziGallery(hanzi) 
      return data.galleries;
    }

    getGalleryBook(hanzi) {
      const data = getHanziGallery(hanzi) 
      return data.gallerybook;
    }

    getSearchState(hanzi) {
      const galleries = this.getGallery(hanzi);
      const img = this.getImage(hanzi);
      const video = this.getPayVideo(hanzi);
      const gallerybook = this.getGalleryBook(hanzi);
      const galleryvideo = this.getFreeVideo(hanzi);

      return {
        galleries,
        hanzi,
        img,
        video,
        gallerybook,
        galleryvideo,
      }
    }

    showHanzi(hanzi) {
      const {img, video, galleries, gallerybook, galleryvideo}  = this.getSearchState(hanzi);
      if (hanzi) {
        this.setState({
          hanzi: hanzi,
          hanziImage: img,
          hanziPayVideo: video,
          galleries,
          gallerybook,
          freevideo: galleryvideo,
        })
      }
    }

    handleSubmit() {
      const target = document.getElementById("searchInput")
      const hanzi = target.value
      window.history.pushState(null, null, `/search/${hanzi}`)

      this.setState({
        hanziImage: "",
        isLoading: true,
      })

      setTimeout (()=>{
        this.showHanzi(hanzi)
        this.setState({
          isLoading: false,
        })
      }, 200)
    }

    handleBrowseHanzi(direction) {
      const hanzi = getHanziByOffset(this.state.hanzi, direction)
      window.history.pushState(null, null, `/search/${hanzi}`)
      this.showHanzi(hanzi)
    }

    handleClickThumbnail(hanzi) {
      const scrollToTop = () => {
        window.scrollTo({ 
          top: 111, 
          behavior: "smooth" 
        })
      }
      window.history.pushState(null, null, `/search/${hanzi}`)

      this.setState({
        hanziImage: "",
        isLoading: true,
      })

      setTimeout (()=>{
        this.showHanzi(hanzi)
        this.setState({
          isLoading: false,
        })
      }, 200)
      scrollToTop()
    }

    // ---------- Show hanzi by radicals ---------- 对每个tab的汉字使用不同的变量名，是为了使每个tab保留独立的汉子查询结果
    getRadicalGallery(rad) {
      const data = getGalleryOfRadical(rad)
      return data.galleries;
    }

    getRadicalGalleryState(rad) {
      const galleries = this.getRadicalGallery(rad);
      const hanzi = galleries[0];
      const img = this.getImage(hanzi);
      const video = this.getPayVideo(hanzi);

      return {
        galleries,
        hanzi,
        img,
        video
      }
    }

    showRadicalGallery(rad) {
      const { galleries, hanzi, img, video } = this.getRadicalGalleryState(rad);

      if (galleries) {
        this.setState({
          currentTab: '2',
          radical: rad,
          galleryOfRadical: galleries,
          hanziOfRadical: hanzi,
          hanziImageOfRadical: img,
          hanziPayVideoOfRadical: video,
        })
      }
    }

    showHanziOfRadical(hanzi) {
      const { img, video } = this.getCatHanziState( hanzi );

      if (hanzi) {
        this.setState({
          hanziImageOfRadical: img,
          hanziPayVideoOfRadical: video,
        })
      }
    }

    handleClickRadical(rad) {
      // clear previous thumbnails
      this.setState ({
        galleryOfRadical: []
      })
      window.history.pushState(null, null, `/radical/${rad}`)
      // here, set loading to true
      this.setState({
        isLoading: true
      })
      setTimeout(() => {
        this.showRadicalGallery(rad)
        //  here set loading to false, or hide loading
        this.setState({
          isLoading: false
      })
      }, 300)
    }

    handleClickThumbByRadical(hanzi) {
      const scrollToImg = () => {
        window.scrollTo({ 
          top: 3500, 
          behavior: "smooth" 
        })
      }
      const data = getHanziData(hanzi)
      const rad = data.rad
      window.history.pushState(null, null, `/radical/${rad}/${hanzi}`)
      this.showHanziOfRadical(hanzi)
      scrollToImg()
    }

    // ---------- Show hanzi by categories ----------
    getCatGallery(cat) {
      const data = getGalleryOfCat(cat)
      return data.galleries;
    }

    getCatGalleryState(cat) {
      const galleries = this.getCatGallery(cat);
      const hanzi = galleries[0];
      const img = this.getImage(hanzi);
      const video = this.getPayVideo(hanzi);

      return {
        galleries,
        hanzi,
        img,
        video
      }
    }

    getCatHanziState(hanzi) {
      const img = this.getImage(hanzi) || "";
      const video = this.getPayVideo(hanzi) || "";

      return {
        hanzi,
        img,
        video,
      }
    }

    showCatGallery(cat) {
      const { galleries, hanzi, img, video } = this.getCatGalleryState(cat);

      if (galleries) {
        this.setState({
          currentTab: '3',
          category: cat,
          galleryOfCat: galleries,
          hanziOfCat: hanzi,
          hanziImageOfCat: img,
          hanziPayVideoOfCat: video,
        })
      }
    }

    showHanziOfCat(hanzi) {
      const { img, video } = this.getCatHanziState( hanzi );

      if (hanzi) {
        this.setState({
          hanziImageOfCat: img,
          hanziPayVideoOfCat: video,
        })
      }
    }

    handleClickCat(cat) {
      // clear previous thumbnails
      this.setState({
        galleryOfCat: []
      })
      window.history.pushState(null, null, `/category/${cat}`)
      // here, set loading to true
      this.setState({
        isLoading: true
      })
      setTimeout(() => {
        this.showCatGallery(cat)
        //  here set loading to false, or hide loading
        this.setState({
          isLoading: false
      })
      }, 600)
    }

    handleClickThumbByCat(hanzi) {
      const scrollToImg = () => {
        window.scrollTo({ 
          top: 3500, 
          behavior: "smooth" 
        })
      }
      const data = getHanziData(hanzi)
      const cat = data.cat
      window.history.pushState(null, null, `/category/${cat}/${hanzi}`)
      this.showHanziOfCat(hanzi)
      scrollToImg()
    }

    // ---------- render the main content ----------
    render() {
      return <div className="content">
        <Tabs defaultActiveKey={this.state.currentTab}>
          <TabPane tab={
            <span>
            <Icon type="search" />
            搜一搜
            </span>
          } key="1">
          <div> 
            <div className="searchsection">
              <SearchBar onSubmit={this.handleSubmit} hanzi={this.state.hanzi}/>
            </div>
            <div className="hanzihuasection">
            <Spin className="searchImgLoadingSpin" spinning={this.state.isLoading?true:false} size="large"/>
              <Hanzihua img={this.state.hanziImage} />
                <BrowseHanziButton direction={-1} onClick={() => (this.handleBrowseHanzi(-1))}/>
                <BrowseHanziButton direction={1} onClick={() => (this.handleBrowseHanzi(1))}/>
            </div>
            <div className="linksection">
                <PayVideoButton payvideo={this.state.hanziPayVideo}/>
                <DownloadButton gallerybook={this.state.gallerybook}/>
            </div>
            <div> 
              <Divider />
              <h3><Button shape="circle" type="primary"><Icon type="select" /></Button><Button type="link">点击卡片，学更多字、古诗、节气…</Button></h3>
            </div>
            <div className="gallerysection">
              <div className="panel">
                { this.state.galleries.map(hanzi => <Thumbnail img={this.getImage(hanzi)} onClick={() => (this.handleClickThumbnail(hanzi))} />) }
              </div>
            </div>
          </div>
          </TabPane>
          <TabPane tab={
            <span>
            <Icon type="build" />
            按部首学
            </span>
          } key="2">
            { this.state.radicals.map(rad => <RadicalButton option={rad} onClick={() => (this.handleClickRadical(rad))} />)}
            <Divider>
              <Icon type="caret-down" />
            </Divider>
            <div className="thumbnailsbyradical">
              <Spin spinning={this.state.isLoading?true:false} size="large"/>
            { this.state.galleryOfRadical.map(hanzi => <ThumbByCat img={this.getImage(hanzi)} onClick={() => (this.handleClickThumbByRadical(hanzi))} />) }
            </div>
            <Divider >
              <Icon type="caret-down" />
            </Divider>
            <div className="singlehanzisectionByCat">
              <Hanzihua img={this.state.hanziImageOfRadical} />
                <FloatVideoButton payvideo={this.state.hanziPayVideoOfRadical}/>
            </div>
          </TabPane>
          <TabPane tab={
            <span>
            <Icon type="apartment" />
            分类学
            </span>
          } key="3">
            { this.state.categories.map(cat => <RadicalButton option={cat} onClick={() => (this.handleClickCat(cat))} />)}
            <Divider>
              <Icon type="caret-down" />
            </Divider>
            <div className="thumbnailsbyradical">
              <Spin spinning={this.state.isLoading?true:false} size="large"/>
            { this.state.galleryOfCat.map(hanzi => <ThumbByCat img={this.getImage(hanzi)} onClick={() => (this.handleClickThumbByCat(hanzi))} />) }
            </div>
            <Divider >
              <Icon type="caret-down" />
            </Divider>
            <div className="singlehanzisectionByCat">
              <Hanzihua img={this.state.hanziImageOfCat} />
                <FloatVideoButton payvideo={this.state.hanziPayVideoOfCat}/>
            </div>
          </TabPane>
        </Tabs>
        <div>
          <BackTop visibilityHeight="50" />
            <strong style={{ color: 'rgba(64, 64, 64, 0.6)' }}></strong>
        </div>
      </div>
    }
  };

  // 开放付费视频，暂时注释掉免费视频: <HanziVideo freevideo={this.state.freevideo}/> 

  // 去掉搜索框上方、下方文字：
  // <h3><Button shape="circle" type="primary">1</Button><Button type="link">搜索一个字，开始学习：</Button></h3>
  // <Divider />
  // <h3><Button shape="circle" type="primary">2</Button><Button type="link">看一看视频，读一读、写一写：</Button></h3>
  // <h3><Button shape="circle" type="primary">3</Button><Button type="link">点击卡片，学更多字、古诗、节气…</Button></h3>

  export { SearchHanzi };