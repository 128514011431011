import { HANZI_DATA } from './HanziData'
import { GALLERY_DATA } from './HanziGalleryData'
//import { RADICAL_DATA } from './Radicals'

function getValidIndex(index, length) {
    if (index < 0 ) {
        return index + length;
    } else if (index >= length) {
        return index - length;
    } else {
        return index
    }
}

export function getHanziData(hanzi, offset) {

    // read hanzi data from JSON or server

    const hanziList = HANZI_DATA['hanziRawData']
    const foundHanzis = hanziList.filter(d => d.value === hanzi)
    if (foundHanzis && foundHanzis.length > 0) {
        let foundHanzi = foundHanzis[0]
        const index = hanziList.indexOf(foundHanzi)
        if (index !== -1 && offset) {
            const validIndex = getValidIndex(index + offset, hanziList.length)
            foundHanzi = hanziList[validIndex]
        }
        return {
            value: foundHanzi.value,
            imgs: foundHanzi.imgs,
            //video: foundHanzi.videos,
            payvideo: foundHanzi.payvideos,
            rad: foundHanzi.radicals,
            cat: foundHanzi.categories,
        }
    } else {
        return {
            value: hanzi,
            imgs: "",
            //video: "",
            payvideo: "",
            rad: "",
            cat: "",
        }
    }
    // return {
    //     img: `Hanzihua/${hanzi}.jpeg`, 
    //     video: "//player.bilibili.com/player.html?aid=78330855&cid=141031082&page=5"
    // }
}

export function getHanziByOffset(hanzi, offset) {
    const data = getHanziData(hanzi, offset)
    return data.value
}

export function getHanziGallery(hanzi){
    const galleryData = GALLERY_DATA['galleryData']
    const foundGalleries = galleryData.filter(d => d.hanzis.includes(hanzi))

    if (foundGalleries && foundGalleries.length > 0) {
        let foundGallery = foundGalleries[0]
        return {
            galleries: foundGallery.hanzis.filter(d => !d.includes("节气")),  // added special handling for "节气"
            gallerybook: foundGallery.freebooks[0],
            galleryvideo: foundGallery.freevideos[0]
        }
    } else {
        return {
            galleries: [],
            gallerybook: "",
            galleryvideo: ""
        }
    }
}

// 获取每个部首对应的汉字
export function getGalleryOfRadical(rad) {

    // read hanzi data from JSON or server
    const hanziList = HANZI_DATA['hanziRawData']
    const foundHanzis = hanziList.filter(d => d.radicals === rad)

    //const radicalList = RADICAL_DATA['radicalData']
    //const foundRadicals = radicalList.filter(d => d.value === radical)

    if (foundHanzis && foundHanzis.length > 0 ) { //&& foundRadicals && foundRadicals.length > 0
        return {
            galleries: foundHanzis.map(d => d.value),
            // gallerybook: "",
            // galleryvideo: "",
            // radicalName: `${radical.value}${radical.description}`
        }
    } else {
        return {
            galleries: [],
            // gallerybook: "",
            // galleryvideo: "",
            // radicalName: ""
        }
    }
}

// 获取每个类别对应的汉字
export function getGalleryOfCat(cat) {

    // read hanzi data from JSON or server
    const hanziList = HANZI_DATA['hanziRawData']
    const foundHanzis = hanziList.filter(d => d.categories === cat)

    if (foundHanzis && foundHanzis.length > 0 ) { 
        return {
            galleries: foundHanzis.map(d => d.value),
        }
    } else {
        return {
            galleries: [],
        }
    }
}