import React from 'react';
//import logo from './logo.svg';
import './App.css';
import {SearchHanzi} from './Hanzihua'; 
import Header from './Header';
import Footer from './Footer';
import { Layout } from 'antd';
//import Menu, { SubMenu, MenuItem } from 'rc-menu';

function App() {
  return (
    <div className="App">
      <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no"></meta>
      <Header />
      <Layout >
        <SearchHanzi />
      </Layout>
      <Footer />
    </div>
  );
}

export default App;
