export const HANZI_DATA = {"hanziRawData": [
    {"value":"宀","radicals":"宀","categories":"NA","imgs":"https://pic.rmb.bdstatic.com/ace2bf0ec667fb7046dbfacf535fe80f.jpeg","payvideos":"NA","videos":"NA"},
    {"value":"宵","radicals":"宀","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/08b59e46e67d30086acd72ca4613daad.jpeg","payvideos":"NA","videos":"NA"},
    {"value":"安","radicals":"宀","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/b7c457bcb1adae0047e1afc13be34354.jpeg","payvideos":"NA","videos":"NA"},
    {"value":"宅","radicals":"宀","categories":"建筑","imgs":"https://pic.rmb.bdstatic.com/38913fde90f722521e7408c790561c9a.jpeg","payvideos":"NA","videos":"NA"},
    {"value":"一","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/0b3f2ae5d54b93b17883b68192f5b14f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"二","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/18ec5a1ca43bcf8c6f985160630a4cbd.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"三","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/e70efdef51513d71714652b66005c3a5.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"四","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/4dae9fc815c5dcda84bbde87c9383b30.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"五","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/6a9cf07f02e8a1a873571b7a1a83c694.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"六","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/396975e458b385aa000d8ba4fc32496f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"七","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/875c3846701631a4efc83caf14e071fc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"八","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/8e7baf634995c6ec4ab718069b47e5b8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"九","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/9e61147ebbca7dee239c4a79f5933099.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"十","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/c1077208b1c07408dde3878afe2c8525.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278278","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"两","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/d2a1b6570a09c3727353dbb1ee189975.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387810","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"半","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/f3da968170ebb99e49bc296123333d5a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278300","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"几","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/3891eb11f510f527ec71962f16d61315.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278290","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"多","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/6391e3c4b3719416dcbfdd218883f044.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387804","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"少","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/1407b7d1291b426ef3ba56478c8ca56a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387807","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"个","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/01d67a9065ecaaa91d5239bff7f68910.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387813","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"只","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/8771380fc17f749746e1191dbee97b0c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278292","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"片","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/1755296b5ae45ca8344b378759a7e761.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14279002","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"双","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/ddc6600bb4fae18ef602207939f29483.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278827","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"串","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/ac10e6ac6e052944fde7584ebd8bd2da.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278829","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"点","radicals":"灬","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/13558b2cb34b37441ef8f15e97a684de.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278822","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"条","radicals":"夂","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/70e51ad7b25f533aba900de5ce57fe31.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278816","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"群","radicals":"NA","categories":"数量词","imgs":"https://pic.rmb.bdstatic.com/aa33fce7b0980bd5b3ab99d5960cbd65.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14278813","videos":"//player.bilibili.com/player.html?aid=78331637&cid=134031230&page=1"},
    {"value":"目","radicals":"目","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/e5f56aeb2a3190e0f2dd3ceb43da859d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282141","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"耳","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/5f779f40dfdf11dbab5c10d48831cf79.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282137","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"口","radicals":"口","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/4b0ee3046a7b084fc9fd718e67cf58a6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282131","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"牙","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/1f6106a8d69e226b10c4bafd3c522f05.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282148","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"手","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/cbb6c417e819b19ad9293e467246d91d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282145","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"足","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/fa3ae10f16f2c780f09f56e36c491886.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282146","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"心","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/bab4990ae8997f791da4bd2ac79becaa.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282150","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"头","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/c768eee09c46e9f326ac011185727b96.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282151","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"皮","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/9fe29235fff5f21007f725b8336a2da6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282164","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"发","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/ec072ea6f185670117c9a778d6bfff64.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895160","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"尾","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/09d6f81654e170a88ac17fd79d63e70c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282156","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"巴","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/edd3fc0c7db14b80445766566b52f5b5.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282158","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"步","radicals":"NA","categories":"身体","imgs":"https://pic.rmb.bdstatic.com/6c380321da632af200754e6b2d193082.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282167","videos":"//player.bilibili.com/player.html?aid=78943627&cid=136777878&page=1"},
    {"value":"天","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/57acbb1dacc43cc88a1e5dc9fa4427e1.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390036","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"地","radicals":"土","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/aa52a58e03e64fa12f256b83525eb61e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390037","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"气","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/8c1c53c6ecf8d8fe128beada76183c70.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282389","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"云","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/b00ef56c19f50edf751581f4558cba6c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282391","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"风","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/312bd7b15220e60fa0e6b5e2ee4c02f9.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282392","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"雨","radicals":"雨","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/e3216fd986e54911a3ba9800533c2e9f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282393","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"雪","radicals":"雨","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/35a1550ccb3a9f2076856b0f7bf598aa.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282395","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"水","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/aa5e79f525fd702837cb777683c8255c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282401","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"江","radicals":"氵","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/3251183dfafbfe48a2b59f880777b767.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282423","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"海","radicals":"氵","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/431e7ff0267b680ab687cb83be7db5a8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282425","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"日","radicals":"日","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/a4f863e63ab8806f844a292e27c2a14e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390085","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"月","radicals":"月","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/90f49908825bfb4e910ac843adbf0c54.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390083","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"星","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/faf42f27b0b641c03f1d939310df16fa.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282405","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"影","radicals":"彡","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/b204bfef74ceb45f58291b5ca59654ea.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282420","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"山","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/b948bb53f8dd146aefac812b1f0d59ae.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282396","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"石","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/135d77ea0bf6983cb34ab9af161e8db7.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282398","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"土","radicals":"土","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/62c871c743159953a41a3dc9c39cbc94.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282416","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"火","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/e951d061324e3c35b02eb9b5e9f7ec7a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282403","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"尘","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/6b45ac96e96bc4721fc75aa9b734fb75.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284197","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"洞","radicals":"氵","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/7bf04714064a7c55b68ddd984be53de0.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284533","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"声","radicals":"NA","categories":"自然","imgs":"https://pic.rmb.bdstatic.com/0803200fadf3a911ce27b9034d9e5bca.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282419","videos":"//player.video.iqiyi.com/73b8a484b4bd2dde12fba935a2cbe7fb/0/0/v_19rv1amb0w.swf-albumId=10130954300-tvId=10130954300-isPurchase=0-cnId=undefined"},
    {"value":"木","radicals":"木","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/856d76ff244cb68be4866899d1791466.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268340","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"林","radicals":"木","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/4ab1066af92c29497a4520a7dc6f2884.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268344","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"森","radicals":"木","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/76d1fbc819d534b82c2cb1b4f7c973cc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14267489","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"树","radicals":"木","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/66568345b994a579d3f62b7e4382a991.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14267993","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"叶","radicals":"口","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/885d4c147a29fc4caf777887feb8bff6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268302","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"本","radicals":"NA","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/7369b6cb354dc5ac7079e2ae79d9130c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12822349","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"果","radicals":"NA","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/6bdfced8e69a35d43955a7f89c97b8f2.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268310","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"杏","radicals":"NA","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/17376d39ad746d2f1989a2bb8131f593.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268377","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"苹","radicals":"艹","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/7ad9ad189b8a5f017cbd54a1af2a6e17.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268374","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"芽","radicals":"艹","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/9fc19b0a203646b0a20b290e62576d70.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268328","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"草","radicals":"艹","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/78392c35fb2773b7bdade976ab21c7fb.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268330","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"花","radicals":"艹","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/87aed4d677666a3d6538ffe836598d67.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268360","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"莲","radicals":"艹","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/0422e0d31144ee7f5b64aba698cacaed.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268376","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"桃","radicals":"木","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/8009adce27f7291fea0b91b3b04cdee6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268379","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"田","radicals":"NA","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/bc03a6658005062aee5cdfd392ecd7aa.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268369","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"禾","radicals":"禾","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/707b7f15690811004cd06b9df87da266.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268370","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"竹","radicals":"⺮","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/adcadf2d5675104444a27e144147b631.jpeg","payvideos":"https://m.lizhiweike.com/lecture/14736394","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"豆","radicals":"NA","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/6236b08301886258d2ccd6620cdc0c81.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268381","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"瓜","radicals":"NA","categories":"植物","imgs":"https://pic.rmb.bdstatic.com/a255872f3a51a086aef5a12284bd6990.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14268382","videos":"//player.bilibili.com/player.html?aid=79624712&cid=136273839&page=1"},
    {"value":"鼠","radicals":"NA","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/c409fffecdd85b9c290044ba27a98a4c.jpeg","payvideos":"NA","videos":"NA"},
    {"value":"鸟","radicals":"鸟","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/aa94363b948da807cbd898c5ab6a1c58.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282347","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"虫","radicals":"虫","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/bc84117019d52da0ce99c9fd9f4d1c74.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282348","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"鱼","radicals":"NA","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/976f7e85c8ba54c0409b75bb58779ad3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282350","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"兔","radicals":"⺈","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/d0548d46dda824a0f7ed22bdfb0fb750.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284202","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"马","radicals":"NA","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/50f9c57581095b7c78dbd9958723ce71.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282362","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"牛","radicals":"NA","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/fc0ae44aff70fc5fdbfe4ef03860f720.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282373","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"羊","radicals":"NA","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/ebb5601b3052abee35ab845c4a3d8e47.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282374","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"猫","radicals":"犭","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/fc369b27b4c96eeb91006e153ec34c56.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282375","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"狗","radicals":"犭","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/3058d5de9da1b0b241a6829f1017a326.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282376","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"鸡","radicals":"鸟","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/baa99b31819e398e368a7cb448fd59d2.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282353","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"鸭","radicals":"鸟","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/b8d8ffaed73211796afc800dbe2dbecf.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282355","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"鸦","radicals":"鸟","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/09d4e9a5e7c5efed3e0849832279723b.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282360","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"蛙","radicals":"虫","categories":"动物","imgs":"https://pic.rmb.bdstatic.com/bb9bc7c5a4c126e98c8c5e8068a8f70e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282378","videos":"//player.bilibili.com/player.html?aid=78330855&cid=134029693&page=1"},
    {"value":"人","radicals":"人","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/77dd9f4c5e7692587f4d27a3625feb57.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387360","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"女","radicals":"女","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/fe368939220a9f42578efe4aa395a49f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284263","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"男","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/3c5e1515779e8c7708c162a575b2abdf.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284268","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"爸","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/64296c633388007fd836d339c0c57b22.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284277","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"妈","radicals":"女","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/0bad573f3226ee29f8383235ed7eae64.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284276","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"姐","radicals":"女","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/0ee710ce063d3788e997b63fe9866365.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284311","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"妹","radicals":"女","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/42fd8304218ad3aee8841257cad58e14.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284314","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"哥","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/12a7d32c1214de54c8d8b3f661b42503.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284318","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"弟","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/0bf126e25dec948c55f74e783a763dd4.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284319","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"公","radicals":"八","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/cdf1241659b6c012cef406c7f8a9369f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284308","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"爷","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/c1980a7b918c8278b84ee492abde9d0e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284309","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"叔","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/44dc1af5d954100a5df22d8085142176.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284332","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"师","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/69ee444734e692957f433f80edc7cc29.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284564","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"朋","radicals":"月","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/d8feb6241734b17d66602a5f97298582.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284342","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"友","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/8f078e3b7399e63267b55c1cead7f317.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284344","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"我","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/0ca3229c757ffa2ee02c3f7f4b656b12.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390028","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"你","radicals":"亻","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/7fa6f5b38f38a5b93ec10bf12107cbf3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387142","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"他","radicals":"亻","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/a1824ffb89451153d6aa408db3a7ee57.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387167","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"她","radicals":"女","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/ab93d66bf627ec377611ab1cc39c32b1.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387339","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"它","radicals":"宀","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/8f911cbd7b21cf961b1b9f3f09f66798.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387347","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"们","radicals":"亻","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/0fd2b7591f7aa2d2bb66c8bdb6f26c8c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387350","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"自","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/81f999ee8dad05b306a1e939bc8c2974.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387763","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"己","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/8138e562adb49d808beee3bb1b05e747.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284272","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"子","radicals":"子","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/01628a50a375084110510ddc12cb0539.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387766","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"孩","radicals":"子","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/facc6c57b9c8c8a5bd9959a2f5543c61.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284340","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"儿","radicals":"NA","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/57853ea60cb27e8d93b184378026de39.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387784","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"娃","radicals":"女","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/c854a44bf10a38aeab2c2e62775daf40.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284339","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"众","radicals":"人","categories":"人物","imgs":"https://pic.rmb.bdstatic.com/e56e825779de30862a0ea5ece1778a71.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284345","videos":"//player.bilibili.com/player.html?aid=78617852&cid=134519137&page=1"},
    {"value":"伞","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/a3fa4a9b43d921b28e5913330f5aa549.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284354","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"衣","radicals":"亠","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/5b02119da01309af1b58f280f9b9a7be.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284158","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"包","radicals":"勹","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/0cca81e304123759fa35ccad3f43a508.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284171","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"书","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/2a7ccbbab6ebc8f9468d3dbd4b51b763.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284167","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"纸","radicals":"纟","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/58ae7eef326d585c8d60bda55c98cb06.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284357","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"笔","radicals":"⺮","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/f780affa7224f5ded175447baf775881.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284358","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"尺","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/f0eaf82a51dd637840f8288ba1717556.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284170","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"刀","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/30d2675898bb2e2fd65845ae7d001b21.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284183","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"工","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/fe38f8357d9b656aee5c879785093c36.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12819846","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"力","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/efab1c83cdd361aea051fec0fedc22d4.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284181","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"贝","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/54dd93310f6afba7f492c284e219d758.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284362","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"桌","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/db672ebb5232700ad7706d5f14cc9808.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284368","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"棋","radicals":"木","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/0148c7b662c563347d11c2125ff8560a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284370","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"旗","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/f57026a44033d21b82d185febb3cf59a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284365","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"医","radicals":"NA","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/42fef3cbfc0da4c9adcc37280e44bde9.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284374","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"奶","radicals":"女","categories":"物品","imgs":"https://pic.rmb.bdstatic.com/cd7b1bac3c1619ece78194d8403987b4.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284372","videos":"//player.bilibili.com/player.html?aid=80350088&cid=137506109&page=1"},
    {"value":"车","radicals":"NA","categories":"交通","imgs":"https://pic.rmb.bdstatic.com/84d221dcc8622b7fc912692f09bb9a1c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284548","videos":"//player.bilibili.com/player.html?aid=78717162&cid=134688271&page=1"},
    {"value":"船","radicals":"NA","categories":"交通","imgs":"https://pic.rmb.bdstatic.com/5affc82ffb1e3b41c40a51a4791dc6eb.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284544","videos":"//player.bilibili.com/player.html?aid=78717162&cid=134688271&page=1"},
    {"value":"桥","radicals":"木","categories":"建筑","imgs":"https://pic.rmb.bdstatic.com/345f24861f5644c69ae6c89c0bc02233.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284537","videos":"//player.bilibili.com/player.html?aid=78717162&cid=134688271&page=1"},
    {"value":"台","radicals":"NA","categories":"建筑","imgs":"https://pic.rmb.bdstatic.com/e50d0c3e2fe0b45c880043fa88b29489.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284540","videos":"//player.bilibili.com/player.html?aid=78717162&cid=134688271&page=1"},
    {"value":"家","radicals":"宀","categories":"建筑","imgs":"https://pic.rmb.bdstatic.com/b89e4b1f8b0c8d1a13e95288e11644c9.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390041","videos":"//player.bilibili.com/player.html?aid=78717162&cid=134688271&page=1"},
    {"value":"院","radicals":"阝","categories":"建筑","imgs":"https://pic.rmb.bdstatic.com/496bf7e634faf1e6f08a5616002f4b7a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284542","videos":"//player.bilibili.com/player.html?aid=78717162&cid=134688271&page=1"},
    {"value":"厂","radicals":"厂","categories":"建筑","imgs":"https://pic.rmb.bdstatic.com/9daf9e3d85db88b1b0a295b541db43f6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284550","videos":"//player.bilibili.com/player.html?aid=78717162&cid=134688271&page=1"},
    {"value":"红","radicals":"纟","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/2cb2e2dce84c7bbf1d096c59daad9e28.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284229","videos":"NA"},
    {"value":"绿","radicals":"纟","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/f61209ef224e77c8fc2d7c97ac62d9df.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284236","videos":"NA"},
    {"value":"蓝","radicals":"艹","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/9de5192869aa9823843b44cb0c11fda3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284241","videos":"NA"},
    {"value":"黄","radicals":"NA","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/9c85b561a2b4c4caf3c45911e9bac41f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284230","videos":"NA"},
    {"value":"金","radicals":"人","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/dd03447471c7218a6f81685d63bcaeb9.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284232","videos":"NA"},
    {"value":"黑","radicals":"灬","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/163bbebb42dded9008f9fa7f5b2ed3e6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284244","videos":"NA"},
    {"value":"乌","radicals":"NA","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/6d8dc537f8b8082fceb232ad5cf6225c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14282357","videos":"NA"},
    {"value":"青","radicals":"NA","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/d05668512f262c01cd44a3f102bf4d0a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284237","videos":"NA"},
    {"value":"白","radicals":"NA","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/98d6a59516b85fd9fd15da4f31e4a331.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284246","videos":"NA"},
    {"value":"彩","radicals":"彡","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/0e9606ece89af0005a2d5a5abb9238a6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284248","videos":"NA"},
    {"value":"色","radicals":"⺈","categories":"颜色","imgs":"https://pic.rmb.bdstatic.com/f1d64ea1b7feb4904dd8ec5a1bdcc602.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284252","videos":"NA"},
    {"value":"东","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/b2604ccb89c6a1d70a561e1399b89013.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284104","videos":"//player.video.iqiyi.com/80badd52e43f9da6a892495fa2f7dffb/0/0/v_19rv9sicgw.swf-albumId=9533290700-tvId=9533290700-isPurchase=0-cnId=undefined"},
    {"value":"西","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/d5017eebca646bb24aa8c1324f38dc71.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284104","videos":"//player.video.iqiyi.com/80badd52e43f9da6a892495fa2f7dffb/0/0/v_19rv9sicgw.swf-albumId=9533290700-tvId=9533290700-isPurchase=0-cnId=undefined"},
    {"value":"北","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/2dbd1555c051d2db894397286bd024a6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284108","videos":"//player.video.iqiyi.com/80badd52e43f9da6a892495fa2f7dffb/0/0/v_19rv9sicgw.swf-albumId=9533290700-tvId=9533290700-isPurchase=0-cnId=undefined"},
    {"value":"南","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/568c6628900b357db7a5ea706ad50585.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284105","videos":"//player.video.iqiyi.com/80badd52e43f9da6a892495fa2f7dffb/0/0/v_19rv9sicgw.swf-albumId=9533290700-tvId=9533290700-isPurchase=0-cnId=undefined"},
    {"value":"上","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/fe93532659632bd0055f655eb1d39ccc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390074","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"中","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/afe84ce5102b3add37ddbbad6c4e6adc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390075","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"下","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/76de18a892f71736c0c23fc68c1aa05a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390076","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"左","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/51cbf962ac7023a182a5e745ad5f2314.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284380","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"右","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/4de22fc071452edd658af32c5021c135.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284381","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"前","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/74455e8a76a1237186a1fe98d6f26bbb.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895090","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"后","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/bfb7cc410528cfcd9de4d357e540960e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895091","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"来","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/27093c7da28838a6ffac675d6c682898.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895086","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"去","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/e5dd5f67072b7051651ba3188635a1cc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895079","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"回","radicals":"囗","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/0a018ba1a51a2462f49ffb275c5f3110.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14431944","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"到","radicals":"刂","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/c0e29d30511e1147585f182ce9fa193d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895089","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"出","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/e84e081176336722de3d421f2c9f9df0.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390077","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"进","radicals":"辶","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/19e2f135c72e047713b1ecf948355928.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390080","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"在","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/4317740e0009083ce72d092ad3d52d3b.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390068","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"向","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/87e08c917784965140dbc3fc120a93bc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284387","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"那","radicals":"阝","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/7de42c94de96cd2a7827812e03ce2030.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390070","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"里","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/3447d65bb2f0174cb5e1c3b0a85f390a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390071","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"旁","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/107567f8289a6a1531d439b707872731.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284390","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"边","radicals":"辶","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/05a4665017f4a9c364585ddf0959e2ed.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284402","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"过","radicals":"辶","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/0239af6deea455fb4d82933bce2c74c8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895242","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"处","radicals":"NA","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/5ad798257de6573811f0642f25c9b84d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284403","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"远","radicals":"辶","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/726ae15bbbbb6102b22a1742908986f1.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284112","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"近","radicals":"辶","categories":"方位","imgs":"https://pic.rmb.bdstatic.com/144edfb06aa1e4ce28a3ade4fd26c41c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284114","videos":"//player.video.iqiyi.com/8e27a415c9889dc43e69b45a9b351fca/0/0/v_19rwixhcp8.swf-albumId=11182540000-tvId=11182540000-isPurchase=0-cnId=undefined"},
    {"value":"春","radicals":"NA","categories":"季节","imgs":"https://pic.rmb.bdstatic.com/bccb02c68b21c7bfe54b11a7e089323a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284042","videos":"//player.bilibili.com/player.html?aid=79827333&cid=136620505&page=1"},
    {"value":"夏","radicals":"夂","categories":"季节","imgs":"https://pic.rmb.bdstatic.com/1f5bf6df181e618ede2af0380eb932a8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284044","videos":"//player.bilibili.com/player.html?aid=79827333&cid=136620505&page=1"},
    {"value":"秋","radicals":"禾","categories":"季节","imgs":"https://pic.rmb.bdstatic.com/dcd56d44cb48f13452fa0ef651fc1fff.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284048","videos":"//player.bilibili.com/player.html?aid=79827333&cid=136620505&page=1"},
    {"value":"冬","radicals":"夂","categories":"季节","imgs":"https://pic.rmb.bdstatic.com/a83e5b484a017396b965966b4d754277.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284051","videos":"//player.bilibili.com/player.html?aid=79827333&cid=136620505&page=1"},
    {"value":"早","radicals":"NA","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/044afd4b58aa60525d724ac90663970f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284500","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"午","radicals":"NA","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/c8dd25e258e4536e28d2c675964e3617.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284093","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"晚","radicals":"日","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/f13624bcc05b2218e673e56b35ed23d4.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284096","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"昨","radicals":"日","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/20a0f91e2735a752593455a02a180ce2.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284088","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"今","radicals":"NA","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/3696ede6b05de1267facfb87ed3d2e1f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284090","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"年","radicals":"NA","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/abcdcc7f8989bae9bfdf9f25231b53c6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390081","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"时","radicals":"日","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/d15f24a8eadfaf3a54d53d0e483b35b6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390086","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"候","radicals":"亻","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/49005e62de7216f535b5a0ae98d09892.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284099","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"久","radicals":"NA","categories":"时间","imgs":"https://pic.rmb.bdstatic.com/ea2e411aa1a723e5418ccd8c42a9a33a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14431946","videos":"//player.video.iqiyi.com/1142930dd7a63cc0b72a36efd6255ef0/0/0/v_19rusg2euc.swf-albumId=10665472200-tvId=10665472200-isPurchase=0-cnId=undefined"},
    {"value":"国","radicals":"囗","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/c42abb66db6ab6ec795faf183c377068.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284557","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"校","radicals":"木","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/7af8c3dd89144a2536601fc177484910.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284559","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"课","radicals":"讠","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/ca5994d63b5e81cc5368a1a22939441e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284568","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"学","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/e39f134d526b03a15b93b1ea3d2fff2d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390057","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"文","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/00452c6e56ea78248421880ad7678178.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284591","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"字","radicals":"宀","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/b8341387b34b4845ba76214949fcdd8f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284577","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"写","radicals":"冖","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/62e9f4c783c6b8ad8fa9355db7742486.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284579","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"词","radicals":"讠","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/157179c53f9efe5460289f77a76dfe49.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284582","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"语","radicals":"讠","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/95d3bcb198e5d825fd052e5cd4998378.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284587","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"句","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/4481dc9c383c38e66192845fdd668073.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284594","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"诗","radicals":"讠","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/504b09d06885bfd35d9d43ab3bd24d1c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284595","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"音","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/456e9fde219a09e125995109567194f6.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284597","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"歌","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/d28b42db79dc86770d879ae1cf85be13.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284598","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"画","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/62610caf30f9d7a949409f72163a400b.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284600","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"数","radicals":"攵","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/443d5ee68a961efd74ebd9fea766ffb0.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284614","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"方","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/eb8578b096c49664cc1e589d997099bb.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12822325","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"法","radicals":"氵","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/ad0a4b63ee72e6e9be97f4190da01b25.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12822328","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"办","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/87b50f0006a0cdaa4dd6734147723bd4.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284481","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"会","radicals":"人","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/3c1010c088998e80c0c3851ea133271e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12804385","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"作","radicals":"亻","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/6d42f6b887e5a07b74220be550fa751b.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895236","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"业","radicals":"NA","categories":"学习","imgs":"https://pic.rmb.bdstatic.com/3e979f7fd0864110d9e8f6d04d03adb4.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284572","videos":"//player.bilibili.com/player.html?aid=79498089&cid=136049054&page=1"},
    {"value":"立","radicals":"立","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/486d9a0679934091468ac4e73abf4bb8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284405","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"站","radicals":"立","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/686903c738fdbe0fa943253f31c98f32.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284410","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"坐","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/bacab52f924147632eeac4fd3c596933.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284411","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"走","radicals":"走","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/a6052ce70e6b9e2638758a74f23ec26c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284415","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"爬","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/fa7598c5fae6b3bec0f77b3425a5a5d8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284416","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"飞","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/1e21b58440d3ce9d085c16a9732df675.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284419","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"生","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/8b7769c0a246113ffa8ced2c94c7123d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390056","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"种","radicals":"禾","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/940cbdd9cebf4a8c3602c9f8383b0d2d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390048","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"采","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/89eb0737287ed764445ce19e7fe2c2dd.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284430","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"睡","radicals":"目","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/7817c68a03b89312ebad49e8fe5572ee.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284443","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"觉","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/85784642e5ac055f5ba9e2a4fb1b7678.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284444","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"看","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/aa6cde15d1c1d671a2c6c0fbe5e372f3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390052","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"见","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/e399d261c007948b00e007bc0c1972ed.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390053","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"听","radicals":"口","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/5b62db03080b955f468c950193a21b85.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284434","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"说","radicals":"讠","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/37a0af04a49d3315f9adbea1adde9fab.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390049","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"问","radicals":"门","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/872e5505e3248f4cc3fcf95523e1c34c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390046","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"闪","radicals":"门","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/d47dcb8540d370494e09441ffd6968e2.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284432","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"用","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/53279eae747adc115f340481504e21c0.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12822646","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"住","radicals":"亻","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/8e98a4a70be81967916dfcb63ba21409.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284436","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"玩","radicals":"𤣩","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/090ca683de4fd2d35b8c225348545780.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284439","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"笑","radicals":"⺮","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/19bdfbe414293f97eb77503ac4d07fd1.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284441","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"穿","radicals":"穴","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/99f0e8cde11fb174d7fb6d4365ee8d6f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284447","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"打","radicals":"扌","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/5e7a5b051d4060eb07ee500a0e59a706.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284450","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"挂","radicals":"扌","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/c193b9a5a2e00af9000cfc702d3fc41a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284453","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"找","radicals":"扌","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/2910f175cee74818858684f26fafd4d3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284456","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"给","radicals":"纟","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/81b9c4204bf1291bb81c6e4e2789dab7.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284457","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"放","radicals":"攵","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/864c60dc14499be5efa13abe737852a1.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284460","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"许","radicals":"讠","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/30cf3199e2cf9ee1b9dd84ac7ee22628.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284462","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"变","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/d4042917c8d604ad9af5be7ebf7a55ce.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284468","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"更","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/b86b39c49221bff8d6f3ebb146b039e1.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284470","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"参","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/58507ff4e330560130f56d7bee960d1d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284474","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"加","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/f9abeb06c932132cfad0517106bbfbe9.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284477","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"服","radicals":"月","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/7cf7e17ec110bec3dd037c8705c0f46e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284482","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"得","radicals":"彳","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/0076f9a69f009a29736adbc6ba0f68d2.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895230","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"从","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/4e996cc250a9260f883d61817d574f0b.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12809800","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"比","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/eded20c8ae84a5b44443a32c8a11f0b0.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284422","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"升","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/af5c27b63a147e0c5a605b11eec6b9c3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284423","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"起","radicals":"走","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/59eeb38b8f41c4d0b653d82c7fa78ee9.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895238","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"开","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/be12bb30cef908bca57ebf304f1c168c.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284425","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"关","radicals":"NA","categories":"动作","imgs":"https://pic.rmb.bdstatic.com/20d8260b0959dfbe25ad9593ad5f81d9.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284428","videos":"//player.bilibili.com/player.html?aid=78333034&cid=136787016&page=1"},
    {"value":"大","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/3fb89fcfecbcd8fa1a51b5ca6cc6762a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387825","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"小","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/ba724dc062f2693133ca79c245f4ac05.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12387802","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"尖","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/3fbe1bf7cd28199e7faf332a27eb40c3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284489","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"长","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/f3b621b05ace2ec344ae29193f13cd30.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895125","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"短","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/fc5d9eeadce870eb584a81fcd86e9cc8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895126","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"空","radicals":"穴","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/80b9e3f00c01863c450602d025dee8f8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284503","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"弯","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/b0e05e9ec2959b25ce6575fa56090dc3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284504","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"高","radicals":"亠","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/dd7c762a412601766e9a2ba0bf6711df.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284516","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"亮","radicals":"亠","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/b19dfaa3b876e59c0be1fe6f66518670.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284519","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"明","radicals":"日","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/bd7741995d22b2e4410930d372448645.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284502","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"快","radicals":"忄","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/5be809b03b456ed24b877156b7577cb5.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284511","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"乐","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/36cd10bbcdb75a1a2e4e9e16eec5dfa1.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284512","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"活","radicals":"氵","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/b4220dbb11d16efad284bc89a49e2666.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284514","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"美","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/2a26baa2ee4091f0f64da2f2d97f9936.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284522","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"丽","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/600f282ae147d1bc7c0b0a250e0dec89.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284522","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"好","radicals":"女","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/5424c08641113ed6260a61569ebb8a73.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895115","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"老","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/c714834d3a8f6ed7bbf3794284f83ae5.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14431955","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"真","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/deaa7e15cc53808de8047a8a9e452020.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284521","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"正","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/8186baf06b133d9480f31589208683a3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284491","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"反","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/a44b2e35d4e4fb81212fa74ebebdabcd.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284494","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"全","radicals":"人","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/3e68d84bca8a9a550fe8b55d6ee651d2.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284527","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"当","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/8be63e31bc721f8492a558be3989c95d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284529","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"对","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/f87deccad3d18910dad3784420cc0aab.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895128","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"同","radicals":"NA","categories":"形容词","imgs":"https://pic.rmb.bdstatic.com/4be74ae2c579712b7b4eb856a95f5520.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895132","videos":"//player.bilibili.com/player.html?aid=78496838&cid=134307643&page=1"},
    {"value":"和","radicals":"禾","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/343b4932904ffe2649a0574d0075a946.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390093","videos":"NA"},
    {"value":"又","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/1cc209759b65c896c0e490d1c2024101.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390097","videos":"NA"},
    {"value":"也","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/4043565aedd438ad8503ef66202ede44.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390098","videos":"NA"},
    {"value":"的","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/dea5c86770a1dc02b76c8cbc1f5d7886.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390106","videos":"NA"},
    {"value":"了","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/e610b900e8de3999692147e2fc9e7c85.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390104","videos":"NA"},
    {"value":"着","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/0b7ad6b53e887c677c051473a01432ba.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895616","videos":"NA"},
    {"value":"是","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/9c1c90705ece41460ba52746cccd4862.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12821430","videos":"NA"},
    {"value":"不","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/367a3eda8e8e94d3253ea4e2849cd12f.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390103","videos":"NA"},
    {"value":"无","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/1a252354ed2c84f6c93036f958425e17.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284625","videos":"NA"},
    {"value":"没","radicals":"氵","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/c7ed2bdc52ea21de1840406656d22109.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895574","videos":"NA"},
    {"value":"有","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/42275cf290ff8796375e7d8bdbf52b92.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12390055","videos":"NA"},
    {"value":"什","radicals":"亻","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/4dcfb56a0da37210dd7ab0f8ec9992dd.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284615","videos":"NA"},
    {"value":"么","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/eee856d5e4458d8aee40b77eb1026708.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284618","videos":"NA"},
    {"value":"谁","radicals":"讠","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/342ad99b2570f8388e17a6c30edf69b2.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284621","videos":"NA"},
    {"value":"呀","radicals":"口","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/5bead5108b35d7a1c6cd83cc494125cb.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284634","videos":"NA"},
    {"value":"吧","radicals":"口","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/ef192f6ba0ae3c71c72f9e67988f74ad.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284637","videos":"NA"},
    {"value":"吗","radicals":"口","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/1d89fc88f9daa321a3607ecaf262b8e3.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284639","videos":"NA"},
    {"value":"才","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/b0183f3b282e04475c8cbb417ce0698a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284632","videos":"NA"},
    {"value":"还","radicals":"辶","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/16320713228669fb9d0a2d9ea7140348.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895572","videos":"NA"},
    {"value":"可","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/8b25180240fbabbe81970936034b1cda.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895577","videos":"NA"},
    {"value":"以","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/49e49807e1b598514aca594b79732f55.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895603","videos":"NA"},
    {"value":"很","radicals":"彳","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/ec63768af48d56f9c3e595524dc6ebb8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284640","videos":"NA"},
    {"value":"最","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/42208dfe75bf4019fe07964d74466c03.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/14284642","videos":"NA"},
    {"value":"就","radicals":"NA","categories":"虚词","imgs":"https://pic.rmb.bdstatic.com/aa5fd431b729133825821242a90edf8b.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12895613","videos":"NA"},
    {"value":"要","radicals":"NA","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895594","videos":"NA"},
    {"value":"把","radicals":"扌","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895610","videos":"NA"},
    {"value":"成","radicals":"NA","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895608","videos":"NA"},
    {"value":"为","radicals":"NA","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895605","videos":"NA"},
    {"value":"民","radicals":"NA","categories":"人物","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12819484","videos":"NA"},
    {"value":"面","radicals":"NA","categories":"物品","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12390042","videos":"NA"},
    {"value":"事","radicals":"NA","categories":"名词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12821132","videos":"NA"},
    {"value":"样","radicals":"木","categories":"植物","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12823057","videos":"NA"},
    {"value":"部","radicals":"阝","categories":"名词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12820072","videos":"NA"},
    {"value":"道","radicals":"辶","categories":"建筑","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895159","videos":"NA"},
    {"value":"这","radicals":"辶","categories":"方位","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12390069","videos":"NA"},
    {"value":"外","radicals":"NA","categories":"方位","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12390072","videos":"NA"},
    {"value":"行","radicals":"彳","categories":"动作","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895227","videos":"NA"},
    {"value":"产","radicals":"NA","categories":"动作","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895240","videos":"NA"},
    {"value":"动","radicals":"NA","categories":"动作","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12390045","videos":"NA"},
    {"value":"分","radicals":"八","categories":"动作","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12822308","videos":"NA"},
    {"value":"坏","radicals":"土","categories":"形容词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895118","videos":"NA"},
    {"value":"之","radicals":"辶","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895570","videos":"NA"},
    {"value":"于","radicals":"NA","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895573","videos":"NA"},
    {"value":"太","radicals":"NA","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895566","videos":"NA"},
    {"value":"能","radicals":"NA","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/classroom/12895593","videos":"NA"},
    {"value":"所","radicals":"NA","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895599","videos":"NA"},
    {"value":"都","radicals":"阝","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895611","videos":"NA"},
    {"value":"而","radicals":"NA","categories":"虚词","imgs":"NA","payvideos":"https://m.lizhiweike.com/lecture2/12895617","videos":"NA"},
    {"value":"节气","radicals":"NA","categories":"NA","imgs":"https://pic.rmb.bdstatic.com/331f2605af9a1891749444f0266e2674.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12184584","videos":"http://open.iqiyi.com/developer/player_js/coopPlayerIndex.html?vid=3f33ecced27250a43bab4886716034c0&tvId=12143230200&accessToken=2.f22860a2479ad60d8da7697274de9346&appKey=3955c3425820435e86d0f4cdfe56f5e7&appId=1368&height=100%&width=100%"},
    {"value":"立春","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/331f2605af9a1891749444f0266e2674.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12184584","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"雨水","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/02c84bd8a085bef2ee35e404efe2e544.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12184595","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"惊蛰","radicals":"虫","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/24ec1839fe033e016dbfd93f6644d92e.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12184590","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"春分","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/e0d07f01764584946b05ebf5b3778624.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12184573","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"清明","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/d2998984c39e1950fb33e9b9084adb68.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12184554","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"谷雨","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/9c7a010643baa5868ca15b15421fd821.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12184569","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"立夏","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/fc0dea7cade4294ed3982fd0ea88f40a.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12272660","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"小满","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/d37e38e213533df721278a9777f13a69.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12272666","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"芒种","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/85248ea17abc0b51a7cac3ef03dd04bc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12272673","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"夏至","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/86fc8efb6d4ae42a5bf96d32e5fb9c74.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12272968","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"小暑","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/f20c5243de1ea923121d1f87b70eaa55.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12272681","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"大暑","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/07d8602aa7c237978276bdcd6bc3df63.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12272684","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"立秋","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/05cb3e6ef8c5f5ac884ddf25fa2bc41d.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770069","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"处暑","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/570a9fb19d8d6cb85ef808257919e907.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770091","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"白露","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/cbd849765c064aae5c2cc1a109ef1ff4.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770144","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"秋分","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/3234b52d63816f92c3a1af54bbf76dcc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770153","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"寒露","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/c5fb48513ad5723374e3efd242409e21.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770159","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"霜降","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/68646d37d9ff0202159f7a7e1cd252e5.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770167","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"立冬","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/6d7124c00a04ec9d07d80b44d43f0cc2.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770171","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"小雪","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/541244a759f7e0e0fb7e8a63f73c54c8.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770229","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"大雪","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/989684578ffbce6c3aec316a6d0b5f18.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770234","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"冬至","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/8acb9019e0db1e060e9546c505864405.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770238","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"小寒","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/5a4574f5b05a62f4ef4c6dc42e81f3ee.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770239","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    {"value":"大寒","radicals":"NA","categories":"节气","imgs":"https://pic.rmb.bdstatic.com/e7edb6277aeac59b7a40eddc330253dc.jpeg","payvideos":"https://m.lizhiweike.com/lecture2/12770242","videos":"//player.bilibili.com/player.html?aid=80217095&cid=137276572&page=1"},
    ]
};